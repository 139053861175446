import React from 'react'

function PrivacyEsSection() {
    return (
        <div class='privacy-html'>
            <h2>POLÍTICA DE PRIVACIDAD</h2>
            Última actualización <strong>3 de febrero de 2024</strong>
            <br/>
            <br/>
            <p>Este aviso de privacidad para NeoArts describe cómo y por qué podríamos recopilar, almacenar, utilizar y/o compartir su información cuando utiliza nuestros servicios, como cuando:</p>
            <p>Visita nuestro sitio web en <a href="https://neoarts.com.co">https://neoarts.com.co</a>, o cualquier otro sitio web nuestro que enlace a este aviso de privacidad</p>
            <p>Interactúa con nosotros de otras formas relacionadas, incluyendo ventas, marketing o eventos</p>
            <p>¿Preguntas o inquietudes? Leer este aviso de privacidad le ayudará a comprender sus derechos y opciones de privacidad. Si no está de acuerdo con nuestras políticas y prácticas, por favor no utilice nuestros servicios. Si aún tiene alguna pregunta o inquietud, por favor contáctenos en <a href="mailto:neoarts.co@gmail.com">neoarts.co@gmail.com</a>.</p>
            <br/>
            <h4>RESUMEN DE PUNTOS CLAVE</h4>
            <p>
            Este resumen proporciona puntos clave de nuestro aviso de privacidad, pero puede obtener más detalles sobre cualquiera de estos temas haciendo clic en el enlace que sigue a cada punto clave o utilizando nuestra tabla de contenidos a continuación para encontrar la sección que busca.
            ¿Qué información personal procesamos? Cuando visita, utiliza o navega por nuestros servicios, podemos procesar información personal según la forma en que interactúa con nosotros y los servicios, las opciones que realiza y los productos y características que utiliza. Obtenga más información sobre la información personal que nos proporciona.
            ¿Procesamos información personal sensible? No procesamos información personal sensible.
            ¿Recibimos información de terceros? Podemos recibir información de bases de datos públicas, socios de marketing, plataformas de redes sociales y otras fuentes externas. Obtenga más información sobre la información recopilada de otras fuentes.
            ¿Cómo procesamos su información? Procesamos su información para proporcionar, mejorar y administrar nuestros servicios, comunicarnos con usted, para seguridad y prevención de fraudes, y para cumplir con la ley. También podemos procesar su información para otros fines con su consentimiento. Procesamos su información solo cuando tenemos una razón legal válida para hacerlo. Obtenga más información sobre cómo procesamos su información.
            ¿En qué situaciones y con qué partes compartimos información personal? Podemos compartir información en situaciones específicas y con terceros específicos. Obtenga más información sobre cuándo y con quién compartimos su información personal.
            ¿Cómo mantenemos segura su información? Tenemos procesos y procedimientos organizativos y técnicos para proteger su información personal. Sin embargo, ninguna transmisión electrónica a través de Internet o tecnología de almacenamiento de información puede garantizarse al 100% de seguridad, por lo que no podemos prometer o garantizar que los piratas informáticos, los ciberdelincuentes u otras terceras partes no autorizadas puedan derrotar nuestra seguridad y recopilar, acceder, robar o modificar incorrectamente su información. Obtenga más información sobre cómo mantenemos segura su información.
            ¿Cuáles son sus derechos? Dependiendo de su ubicación geográfica, la ley de privacidad aplicable puede significar que tiene ciertos derechos con respecto a su información personal. Obtenga más información sobre sus derechos de privacidad.
            ¿Cómo ejerce sus derechos? La forma más fácil de ejercer sus derechos es presentar una solicitud de acceso a datos personales o ponerse en contacto con nosotros. Consideraremos y actuaremos sobre cualquier solicitud de acuerdo con las leyes aplicables de protección de datos.
            ¿Quiere obtener más información sobre qué hacemos con cualquier información que recopilamos? Revise el aviso de privacidad completo.
            </p>
            <br/>
            <br/>
            <h4>ÍNDICE</h4>
            <a href="#section1">1. ¿QUÉ INFORMACIÓN RECOPILAMOS?</a><br/>
            <a href="#section2">2. ¿CÓMO PROCESAMOS SU INFORMACIÓN?</a><br/>
            <a href="#section3">3. ¿CUÁNDO Y CON QUIÉN COMPARTIMOS SU INFORMACIÓN PERSONAL?</a><br/>
            <a href="#section4">4. ¿CUÁNTO TIEMPO GUARDAMOS SU INFORMACIÓN?</a><br/>
            <a href="#section5">5. ¿CÓMO MANTENEMOS SEGURA SU INFORMACIÓN?</a><br/>
            <a href="#section6">6. ¿RECOPILAMOS INFORMACIÓN DE MENORES?</a><br/>
            <a href="#section7">7. ¿CUÁLES SON SUS DERECHOS DE PRIVACIDAD?</a><br/>
            <a href="#section8">8. CONTROLES PARA FUNCIONES DE NO SEGUIMIENTO</a><br/>
            <a href="#section9">9. ¿REALIZAMOS ACTUALIZACIONES A ESTE AVISO?</a><br/>
            <a href="#section10">10. ¿CÓMO PUEDE CONTACTARNOS SOBRE ESTE AVISO?</a><br/>
            <a href="#section11">11. ¿CÓMO PUEDE REVISAR, ACTUALIZAR O ELIMINAR LOS DATOS QUE RECOPILAMOS DE USTED?</a><br/>
            <br/>
            <br/>
            <h4 id="section1">1. ¿QUÉ INFORMACIÓN RECOPILAMOS?</h4>
            
            Información personal que nos proporciona

            En resumen: Recopilamos información personal que usted nos proporciona.

            Recopilamos información personal que usted nos proporciona voluntariamente cuando expresa interés en obtener información sobre nosotros o nuestros productos y servicios, cuando participa en actividades en los servicios, o de otra manera cuando se pone en contacto con nosotros.

            Información personal proporcionada por usted. La información personal que recopilamos depende del contexto de sus interacciones con nosotros y los servicios, las elecciones que realice y los productos y funciones que utilice. La información personal que recopilamos puede incluir lo siguiente:
            nombres
            números de teléfono
            direcciones de correo electrónico
            direcciones postales
            Información sensible. No procesamos información sensible.

            Toda la información personal que nos proporcione debe ser veraz, completa y precisa, y debe notificarnos cualquier cambio en dicha información personal.

            Información recopilada de otras fuentes

            En resumen: Podemos recopilar datos limitados de bases de datos públicas, socios de marketing y otras fuentes externas.

            Con el fin de mejorar nuestra capacidad para proporcionarle marketing relevante, ofertas y servicios, podemos obtener información sobre usted de otras fuentes, como bases de datos públicas, socios de marketing conjuntos, programas de afiliados, proveedores de datos y otros terceros. Esta información incluye direcciones postales, cargos laborales, direcciones de correo electrónico, números de teléfono, datos de intención (o datos de comportamiento del usuario), direcciones IP (Protocolo de Internet), perfiles de redes sociales, URL de redes sociales y perfiles personalizados, con fines de publicidad dirigida y promoción de eventos.
            <br/>
            <br/>
            <h4 id="section2">2. ¿CÓMO PROCESAMOS SU INFORMACIÓN?</h4>

            En resumen: Procesamos su información para proporcionar, mejorar y administrar nuestros servicios, comunicarnos con usted, para seguridad y prevención de fraudes, y para cumplir con la ley. También podemos procesar su información para otros fines con su consentimiento.

            Procesamos su información personal por diversas razones, según la forma en que interactúa con nuestros servicios, incluyendo:
            Para entregar y facilitar la entrega de servicios al usuario. Podemos procesar su información para proporcionarle el servicio solicitado.
            Para responder a consultas/ofrecer soporte a los usuarios. Podemos procesar su información para responder a sus consultas y resolver cualquier problema potencial que pueda tener con el servicio solicitado.
            Para enviarle información administrativa. Podemos procesar su información para enviarle detalles sobre nuestros productos y servicios, cambios en nuestros términos y políticas, y otra información similar.

            Para habilitar comunicaciones entre usuarios. Podemos procesar su información si elige utilizar cualquiera de nuestras ofertas que permiten la comunicación con otro usuario.

            Para solicitar comentarios. Podemos procesar su información cuando sea necesario para solicitar comentarios y ponerse en contacto con usted sobre su uso de nuestros servicios.

            Para enviarle comunicaciones de marketing y promocionales. Podemos procesar la información personal que nos envíe con fines de marketing, si esto está de acuerdo con sus preferencias de marketing. Puede optar por no recibir nuestros correos electrónicos de marketing en cualquier momento. Para obtener más información, consulte " 
            <a href="#section7">
            ¿CUÁLES SON SUS DERECHOS DE PRIVACIDAD?
            </a>
            " a continuación.

            Para ofrecerle publicidad dirigida. Podemos procesar su información para desarrollar y mostrar contenido y publicidad personalizados adaptados a sus intereses, ubicación y más.
            Para publicar testimonios. Publicamos testimonios en nuestros servicios que pueden contener información personal.
            Para proteger nuestros servicios. Podemos procesar su información como parte de nuestros esfuerzos para mantener seguros y protegidos nuestros servicios, incluyendo la monitorización y prevención de fraudes.
            Para evaluar y mejorar nuestros servicios, productos, marketing y su experiencia. Podemos procesar su información cuando creamos que es necesario identificar tendencias de uso, determinar la efectividad de nuestras campañas promocionales y evaluar y mejorar nuestros servicios, productos, marketing y su experiencia.
            Para determinar la efectividad de nuestras campañas de marketing y promocionales. Podemos procesar su información para comprender mejor cómo proporcionar campañas de marketing y promocionales que sean más relevantes para usted.
            <br/>
            <br/>
            <h4 id="section3">3. ¿CUÁNDO Y CON QUIÉN COMPARTIMOS SU INFORMACIÓN PERSONAL?</h4>

            En resumen: podemos compartir información en situaciones específicas descritas en esta sección y/o con los siguientes terceros.

            Es posible que necesitemos compartir su información personal en las siguientes situaciones:
            Transferencias de Negocios. Podemos compartir o transferir su información en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la empresa, financiación o adquisición de todo o una parte de nuestro negocio a otra empresa.<br/>
            <br/>
            <h4 id="section4">4. ¿CUÁNTO TIEMPO CONSERVAMOS TU INFORMACIÓN?</h4>

            En resumen: conservamos su información durante el tiempo necesario para cumplir con los fines descritos en este aviso de privacidad, a menos que la ley exija lo contrario.

            Solo conservaremos su información personal durante el tiempo que sea necesario para los fines establecidos en este aviso de privacidad, a menos que la ley exija o permita un período de retención más prolongado (como requisitos fiscales, contables u otros requisitos legales).

            Cuando no tengamos una necesidad comercial legítima y continua de procesar su información personal, eliminaremos o anonimizaremos dicha información o, si esto no es posible (por ejemplo, porque su información personal se ha almacenado en archivos de respaldo), la procesaremos de forma segura. almacenar su información personal y aislarla de cualquier procesamiento posterior hasta que sea posible su eliminación.<br/>
            <br/>
            <h4 id="section5">5. ¿CÓMO MANTENEMOS SU INFORMACIÓN SEGURA?</h4>

            En resumen: nuestro objetivo es proteger su información personal a través de un sistema de medidas de seguridad técnicas y organizativas.

            Hemos implementado medidas de seguridad técnicas y organizativas apropiadas y razonables diseñadas para proteger la seguridad de cualquier información personal que procesamos. Sin embargo, a pesar de nuestras medidas de seguridad y esfuerzos para proteger su información, no se puede garantizar que ninguna transmisión electrónica a través de Internet o tecnología de almacenamiento de información sea 100% segura, por lo que no podemos prometer ni garantizar que los piratas informáticos, ciberdelincuentes u otros terceros no autorizados no serán capaz de burlar nuestra seguridad y recopilar, acceder, robar o modificar indebidamente su información. Aunque haremos todo lo posible para proteger su información personal, la transmisión de información personal hacia y desde nuestros Servicios es bajo su propio riesgo. Sólo debe acceder a los Servicios dentro de un entorno seguro.
            <br/>
            <br/>
            <h4 id="section6">6. ¿RECOGEMOS INFORMACIÓN DE MENORES DE EDAD?</h4>

            En resumen: no recopilamos datos ni comercializamos deliberadamente a niños menores de 18 años.

            No solicitamos conscientemente datos ni comercializamos a niños menores de 18 años. Al utilizar los Servicios, usted declara que tiene al menos 18 años o que es el padre o tutor de dicho menor y acepta el uso de los Servicios por parte de dicho menor dependiente. Si nos enteramos de que se ha recopilado información personal de usuarios menores de 18 años, desactivaremos la cuenta y tomaremos medidas razonables para eliminar de inmediato dichos datos de nuestros registros. Si tiene conocimiento de algún dato que podamos haber recopilado de niños menores de 18 años, contáctenos en neoarts.co@gmail.com.
            <br/>
            <br/>
            <h4 id="section7">7. ¿CUÁLES SON TUS DERECHOS DE PRIVACIDAD?</h4>

            En resumen: puede revisar, cambiar o cancelar su cuenta en cualquier momento.

            Retirar su consentimiento: si confiamos en su consentimiento para procesar su información personal, que puede ser un consentimiento expreso y/o implícito según la ley aplicable, tiene derecho a retirar su consentimiento en cualquier momento. Puede retirar su consentimiento en cualquier momento poniéndose en contacto con nosotros utilizando los datos de contacto proporcionados en la sección "¿CÓMO PUEDE CONTACTARNOS SOBRE ESTE AVISO?" abajo.

            Sin embargo, tenga en cuenta que esto no afectará la legalidad del procesamiento antes de su retiro ni, cuando la ley aplicable lo permita, afectará el procesamiento de su información personal realizado basándose en motivos de procesamiento legales distintos del consentimiento.

            Optar por no recibir comunicaciones promocionales y de marketing: puede darse de baja de nuestras comunicaciones promocionales y de marketing en cualquier momento haciendo clic en el enlace para cancelar la suscripción en los correos electrónicos que le enviamos, o contactándonos utilizando los detalles proporcionados en la sección "¿CÓMO PUEDE CONTACTARNOS?" ¿SOBRE ESTE AVISO?" abajo. Luego será eliminado de las listas de marketing. Sin embargo, aún podemos comunicarnos con usted, por ejemplo, para enviarle mensajes relacionados con el servicio que sean necesarios para la administración y el uso de su cuenta, para responder a solicitudes de servicio o para otros fines no relacionados con el marketing.

            Si tiene preguntas o comentarios sobre sus derechos de privacidad, puede enviarnos un correo electrónico a tpmonroy18@gmail.com.
            <br/>
            <br/>
            <h4 id="section8">8. CONTROLES PARA FUNCIONES DE NO SEGUIMIENTO</h4>

            La mayoría de los navegadores web y algunos sistemas operativos y aplicaciones móviles incluyen una función o configuración de No rastrear ("DNT") que puede activar para indicar su preferencia de privacidad y no monitorear ni recopilar datos sobre sus actividades de navegación en línea. En esta etapa no se ha finalizado ningún estándar tecnológico uniforme para reconocer e implementar señales DNT. Como tal, actualmente no respondemos a las señales del navegador DNT ni a ningún otro mecanismo que comunique automáticamente su elección de no ser rastreado en línea. Si se adopta un estándar para el seguimiento en línea que debamos seguir en el futuro, le informaremos sobre esa práctica en una versión revisada de este aviso de privacidad.
            <br/>
            <br/>
            <h4 id="section9">9. ¿ACTUALIZAMOS ESTE AVISO?</h4>

            En resumen: Sí, actualizaremos este aviso según sea necesario para cumplir con las leyes pertinentes.

            Es posible que actualicemos este aviso de privacidad de vez en cuando. La versión actualizada se indicará mediante una fecha "Revisada" actualizada y la versión actualizada entrará en vigor tan pronto como esté accesible. Si realizamos cambios sustanciales en este aviso de privacidad, podemos notificarle publicando de manera destacada un aviso de dichos cambios o enviándole directamente una notificación. Le recomendamos que revise este aviso de privacidad con frecuencia para estar informado sobre cómo protegemos su información.
            <br/>
            <br/>
            <h4 id="section10">10. ¿CÓMO PUEDE CONTACTARNOS SOBRE ESTE AVISO?</h4>

            Si tiene preguntas o comentarios sobre este aviso, puede enviarnos un correo electrónico a neoarts.co@gmail.com. NeoArtes, Colombia
            <br/>
            <br/>
            <h4 id="section11">11. ¿CÓMO PUEDES REVISAR, ACTUALIZAR O ELIMINAR LOS DATOS QUE RECOPILAMOS DE TI?</h4>

            Según las leyes aplicables de su país, es posible que tenga derecho a solicitar acceso a la información personal que recopilamos de usted, cambiar esa información o eliminarla. Para solicitar revisar, actualizar o eliminar su información personal, complete y envíe una solicitud de acceso del interesado.
        </div>
    )
}

export default PrivacyEsSection